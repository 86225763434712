<script setup lang="ts">
import { useDisplay } from "vuetify";
import DeleteDialog from "../dialogs/DeleteDialog.vue";
import ConfirmCloseDialog from "../dialogs/ConfirmCloseDialog.vue";
import DialogActions from "../DialogActions.vue";

const properties = defineProps({
  onSave: { type: Function },
  disableSaveInfo: { type: Object as PropType<SaveDisableInfo> },
  deleteInfo: { type: Object as PropType<DeleteInfo> },
  onCancel: Function,
  width: [Number, String],
  name: String,
  persistent: {
    type: Boolean,
    default: true,
  },
  customActions: {
    type: Boolean,
    default: false,
  },
  deleteActions: {
    type: Boolean,
    default: false,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  hideSave: {
    type: Boolean,
    default: false,
  },
  fullscreen: {
    type: Boolean,
    default: false,
  },
  changesOccurred: {
    type: Boolean,
    default: false,
  },
  setFullHeight: { // fixed height
    type: Boolean,
    default: false,
  },
  ctaLabel: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:hideActions", "update:hideSave", "update:modelValue"]);
const { smAndDown } = useDisplay();
const { t } = useI18n();

export interface DeleteInfo {
  name: string;
  onDelete: Function;
  deleteText: string | undefined | null;
}

export interface SaveDisableInfo {
  right: string; // is needed
  what: string; // to disable
  tipPos: string | undefined | null;
}

const syncedHideActions = computed({
  get: () => properties.hideActions,
  set: val => emit("update:hideActions", val),
});

const syncedHideSave = computed({
  get: () => properties.hideSave,
  set: val => emit("update:hideSave", val),
});

const broadcastModule = useBroadcastModule();

const { isOpen: broadcastOpen } = storeToRefs(broadcastModule);

const open = defineModel<boolean>("open");

const loading = ref(false);
const wasOpen = ref(false);
const deleteOpen = ref(false);
const showFalseTop = ref(false);
const showFalseBottom = ref(false);
const confirmCloseOpen = ref(false);

const resolveClose = ref<Function | undefined>(undefined);

const dialogContent = ref<HTMLDivElement | null>(null);

const tipPos = computed(() => properties.disableSaveInfo?.tipPos ?? undefined);

const outsideCancel = computed(() => properties.persistent ? execCancel : () => {});

const defaultLabel = computed(() => properties.ctaLabel || t("components.baseDialog.saveBtn"));

watch(broadcastOpen, (val: boolean) => {
  if (val) {
    wasOpen.value = open.value!;
    open.value = false;
  }
  else { open.value = wasOpen.value; }
});

async function executeSave() {
  loading.value = true;
  const res = await properties.onSave!();
  loading.value = false;
  if (res) { open.value = false; }

  scrollUp();
}

async function execCancel() {
  let closeSubmitted = true;
  if (properties.changesOccurred) {
    confirmCloseOpen.value = true;
    open.value = false;

    const closePromise = new Promise<boolean>(resolve => resolveClose.value = resolve);

    closeSubmitted = await closePromise;
  }
  if (closeSubmitted) {
    if (properties.onCancel) { properties.onCancel(); }

    open.value = false;
    scrollUp();
  }
  else {
    open.value = true;
  }
}

function closeDelete() {
  open.value = true;
}

function openDelete() {
  open.value = false;
  deleteOpen.value = true;
}

function scrollUp() {
  /* istanbul ignore next */
  if (dialogContent.value?.scroll) { dialogContent.value?.scroll(0, 0); }
}

function determineIndicators(e: any) {
  const scrollTop = e.target.scrollTop ?? 0;
  const scrollHeight = e.target.scrollHeight ?? 0;
  const offsetHeight = (e.target.offsetHeight) ?? 0;

  /// leave early if container content height and offset height are the same
  /// -> content can be displayed in max height
  if (scrollHeight === offsetHeight) {
    showFalseTop.value = false;
    showFalseBottom.value = false;
    return;
  }

  if (scrollTop === scrollHeight - offsetHeight) {
    // reached bottom
    showFalseTop.value = true;
    showFalseBottom.value = false;
  }
  else if (scrollTop === 0) {
    // reached top
    showFalseTop.value = false;
    showFalseBottom.value = true;
  }
  else if (scrollTop > 0 && scrollTop <= offsetHeight / 2) {
    // scrolled down a little
    showFalseTop.value = true;
    showFalseBottom.value = true;
  }
}
</script>

<template>
  <div>
    <VDialog
      v-model="open"
      :width="width"
      :persistent="persistent"
      :fullscreen="fullscreen"
      :scrollable="true"
      :height="setFullHeight ? '90vh' : ''"
      @click:outside="outsideCancel"
    >
      <template #activator="{ props }">
        <slot
          name="activator"

          :props="props"
        />
      </template>

      <v-card
        :tile="fullscreen"
        style="border-radius: 20px;"
        class="py-4"
      >
        <v-card-title class="title-large px-8 mt-5">
          <v-row
            v-if="$slots.headerStepper"
            class="align-center"
          >
            <slot name="headerStepper" />
          </v-row>
          <v-row
            no-gutters
            justify="space-between"
          >
            <slot
              v-if="$slots.title"
              name="title"
            />
            <div
              v-else-if="smAndDown"
              style="width: 100px;"
            />
          </v-row>
          <div
            class="btn-close"
            @click="execCancel"
          >
            <XIcon />
          </div>
        </v-card-title>
        <v-card-subtitle class="pt-0 px-8">
          <slot name="subTitle" />
        </v-card-subtitle>
        <div
          v-if="showFalseTop"
          class="shadow-top"
        />
        <v-card-text
          id="dialogContent"
          ref="dialogContent"
          v-scroll.self="determineIndicators"

          class="pt-2 px-8"
          style="position: relative; "
        >
          <slot name="content" />

          <div />
        </v-card-text>

        <div

          class="shadow-bottom"
        />
        <v-card-actions
          v-if="!syncedHideActions"
          style="position:relative;"
          class="px-6"
        >
          <slot
            v-if="customActions"
            name="actions"
          />
          <DialogActions v-else>
            <template #primary>
              <anybill-button
                id="btn-save"
                :disabled="!!disableSaveInfo"
                :loading="loading"
                @click="executeSave"
              >
                {{ defaultLabel }}
              </anybill-button>
            </template>
            <template #secondary>
              <v-btn
                id="btn-abort"
                variant="text"
                color="000"
                tabindex="-1"
                class="mr-2"
                @click="execCancel"
              >
                {{ $t("components.baseDialog.abortBtn") }}
              </v-btn>
            </template>
            <template #ternary>
              <v-btn
                v-if="deleteInfo && !!!disableSaveInfo"
                id="btn-delete"
                variant="text"
                color="000"
                @click="openDelete"
              >
                {{ $t("components.baseDialog.deleteBtn") }}
              </v-btn>
            </template>
          </DialogActions>
        </v-card-actions>
      </v-card>
    </VDialog>
    <ConfirmCloseDialog
      v-model:open="confirmCloseOpen"
      :fullfill-promise="resolveClose"
    />
    <DeleteDialog
      v-if="!!deleteInfo"
      v-model:open="deleteOpen"
      :on-delete="deleteInfo.onDelete"
      :name="deleteInfo.name"
      :on-abort="closeDelete"
      :delete-text="deleteInfo.deleteText || 'delete'"
    />
  </div>
</template>

<style scoped>
    .v-card .v-card-subtitle{
      white-space:normal !important;

    }
.btn-close{
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
    cursor:pointer;
  }
.shadow-top{
    box-shadow:  0 3px 1px -2px rgba(0,0,0,0.20);
    z-index: 999;
    height: 10px;
    width: 100%;
    background-color: transparent;
    z-index: 999;
    margin-top: -10px;
    position: relative;
}

.shadow-bottom{
    height: 10px;
    background-color: transparent;
    z-index: 999;
    position: relative;
    top:0;
    box-shadow: 0px -3px 1px -2px rgba(0,0,0,0.20);
    width:100%;
}
</style>
